import { Component, DestroyRef, EventEmitter, HostBinding, inject, Input, OnInit, Output } from '@angular/core'
import { DatePipe, NgTemplateOutlet } from '@angular/common'
import { trigger } from '@angular/animations'
import { of, Subject } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { Store } from '@ngxs/store'
import { catchError, startWith, switchMap, tap } from 'rxjs/operators'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatIcon } from '@angular/material/icon'
import { TranslateModule } from '@ngx-translate/core'

import { EconomyTabService } from '../../../../../core/api'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { Table } from '../../../../../core/classes/table/table'
import { ReminderHistoryResponse } from './interfaces/reminder-history-response'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { ToggleUserProfile } from 'src/app/shared/modules/user-profile/state/user-profile.actions'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { FloatingElementDirective } from '../../../../../shared/directives/floating-element.directive'
import { ButtonComponent } from '../../../../../shared/components/button/button.component'
import { ProfilePictureComponent, UserActivationStatusComponent } from '../../../../../shared/components'
import { OverlayModule } from '../../../../../shared/components/overlay/overlay.module'
import { GetFullNamePipe } from '../../../../../shared/pipes/get-full-name.pipe'
import { LoaderComponent } from '../../../../../shared/modules/loader/loader.component'
import { FadeInComponent } from '../../../../../shared/components/animations/fade-in.component'

@Component({
  selector: 'app-reminder-history',
  templateUrl: './reminder-history.component.html',
  styleUrls: ['./reminder-history.component.scss'],
  host: { '[@reminderHistoryAnimation]': '' },
  animations: [trigger('reminderHistoryAnimation', OverlayAnimations.detailsPopup)],
  imports: [
    NgTemplateOutlet,
    TranslateModule,
    MatTableModule,
    MatTooltipModule,
    MatIcon,
    DatePipe,
    OverlayModule,
    TableComponent,
    LoaderComponent,
    FadeInComponent,
    ButtonComponent,
    ProfilePictureComponent,
    UserActivationStatusComponent,
    FloatingElementDirective,
    GetFullNamePipe
  ]
})
export class ReminderHistoryComponent implements OnInit {
  @Input() mode: 'overlay' | 'compact' = 'compact'
  @Input() reloadTrigger$: Subject<void> = new Subject()
  @Input() userName: string
  @Input() paymentName: string
  @Input() userPaymentId: number
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<ReminderHistoryResponse> = new Table({
    columns: [Columns.Receiver, Columns.Reminder, Columns.Date, Columns.Email, Columns.PushNotification, Columns.SMS]
  })

  private destroyRef = inject(DestroyRef)

  get Columns() {
    return Columns
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  @HostBinding('class.compact') get compact() {
    return this.mode === 'compact'
  }

  constructor(
    private store: Store,
    private economyTabService: EconomyTabService
  ) {}

  ngOnInit() {
    this.reloadTrigger$
      .pipe(
        startWith(null),
        tap(() => (this.table.loading = true)),
        switchMap(() => this.economyTabService.getUserPaymentReminders(this.userPaymentId)),
        catchError(() => of([])),
        tap((response) => (this.table.data = response)),
        tap(() => (this.table.loading = false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
  }

  openUserProfile(id: number): void {
    this.store.dispatch(new ToggleUserProfile(true, id))
  }

  onClose(shouldSendNewReminder: boolean): void {
    this.close.emit(shouldSendNewReminder)
  }
}
