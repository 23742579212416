import { Component, DestroyRef, EventEmitter, HostBinding, inject, Inject, Input, OnInit, Output } from '@angular/core'
import { trigger } from '@angular/animations'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DatePipe, NgTemplateOutlet } from '@angular/common'
import { SelectSnapshot } from '@ngxs-labs/select-snapshot'
import { TranslateModule } from '@ngx-translate/core'
import { catchError, startWith, switchMap, tap } from 'rxjs/operators'
import { MatTableModule } from '@angular/material/table'
import { MatIcon } from '@angular/material/icon'
import { of, Subject } from 'rxjs'

import { EconomyTabService } from '../../../../../core/api'
import { Columns } from '../../../../../core/enums/table/columns'
import { DateFormat } from '../../../../../core/enums/global/date-format'
import { PaidStatus } from '../enums/paid-status'
import { Table } from '../../../../../core/classes/table/table'
import { InvoiceHistoryResponse } from './interfaces/invoice-history-response'
import { OverlayAnimations } from '../../../../../shared/components/overlay/animations'
import { WINDOW } from '../../../../../core/services/window.service'
import { PaymentTypes } from '../../../../../core/enums/economy/payment-types'
import { EconomyTabState, UserPermissions } from '../states/economy-tab.state'
import { TableComponent } from '../../../../../shared/modules/table/table.component'
import { OverlayModule } from '../../../../../shared/components/overlay/overlay.module'
import { ButtonComponent } from '../../../../../shared/components/button/button.component'
import { FloatingElementDirective } from '../../../../../shared/directives/floating-element.directive'
import { FadeInComponent } from '../../../../../shared/components/animations/fade-in.component'
import { LoaderComponent } from '../../../../../shared/modules/loader/loader.component'

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss'],
  host: { '[@invoiceHistoryAnimation]': '' },
  animations: [trigger('invoiceHistoryAnimation', OverlayAnimations.detailsPopup)],
  imports: [
    NgTemplateOutlet,
    MatTableModule,
    MatIcon,
    TranslateModule,
    DatePipe,
    OverlayModule,
    FadeInComponent,
    LoaderComponent,
    ButtonComponent,
    TableComponent,
    FloatingElementDirective
  ]
})
export class InvoiceHistoryComponent implements OnInit {
  @SelectSnapshot(EconomyTabState.userEconomyPermissions) userEconomyPermissions: UserPermissions

  @Input() mode: 'overlay' | 'compact' = 'compact'
  @Input() reloadTrigger$: Subject<void> = new Subject()
  @Input() userName: string
  @Input() paymentName: string
  @Input() userPaymentId: number
  @Input() paymentType: PaymentTypes
  @Input() paidStatus: PaidStatus

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>()

  table: Table<InvoiceHistoryResponse> = new Table({ columns: [Columns.InvoiceNumber, Columns.GenerationDate] })

  private destroyRef = inject(DestroyRef)

  get Columns() {
    return Columns
  }

  get PaymentTypes() {
    return PaymentTypes
  }

  get PaidStatus() {
    return PaidStatus
  }

  get DateFormat() {
    return DateFormat
  }

  @HostBinding('class.compact') get compact() {
    return this.mode === 'compact'
  }

  constructor(
    @Inject(WINDOW) private window: Window,
    private economyTabService: EconomyTabService
  ) {}

  ngOnInit() {
    this.reloadTrigger$
      .pipe(
        startWith(null),
        tap(() => (this.table.loading = true)),
        switchMap(() => this.economyTabService.getInvoiceHistory(this.userPaymentId)),
        catchError(() => of([])),
        tap((response) => (this.table.data = response)),
        tap(() => (this.table.loading = false)),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe()
  }

  openInvoice(invoice: InvoiceHistoryResponse): void {
    this.economyTabService.getInvoiceUrl(invoice.id).subscribe((response) => this.window.open(response.link, '_blank'))
  }

  onClose(shouldGenerateNewInvoice: boolean): void {
    this.close.emit(shouldGenerateNewInvoice)
  }
}
